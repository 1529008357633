<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue">
                    <el-table :data="yearlyOverviewData" size="mini" :show-header="false">
                        <el-table-column prop="title" width="250" editable>
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.title" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="annualReportYear" editable>
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.annualReportYear" type="number" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="annualReportYearMinusOne" editable>
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.annualReportYearMinusOne" type="number" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="annualReportYearMinusTwo" editable>
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.annualReportYearMinusTwo" type="number" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="annualReportYearMinusThree" editable>
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.annualReportYearMinusThree" type="number" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-show="showComment">
                        <h2 class="text-base font-semibold mb-2 mt-4">Kommentar:</h2>
                        <el-input type="textarea" :disabled="inputDisabled" v-model="localInputText" rows="2" class="break-normal custom-textarea" />
                    </div>
                </div>
            </el-form>
            <CardEditButtons
                :toggle-value="toggleValue"
                :show-edit-button="showEditButton"
                :toggle-edit="toggleEdit"
                :close-modal="closeModal"
                :create="create"
                :show-comment-button="showCommentButton"
                :show-comment="showComment"
                @toggle-comment="handleToggleComment"
            />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        inputText: {
            type: String,
            default: "",
        },
        yearlyOverviewData: {
            type: Array,
            default: () => [],
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        showComment: {
            type: Boolean,
        },
    },

    data() {
        return {
            updatedYearlyOverviewData: [],
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localInputText: this.inputText,
            showCommentButton: true,
            infoContent: `För att uppfylla kraven på en rättvisande översikt enligt 6 kap. 1 § första stycket ÅRL ska företaget för räkenskapsåret och de tre föregående åren lämna uppgift om <br />a) nettoomsättning, <br />b) resultat efter finansiella poster, och <br />c) soliditet (justerat eget kapital i procent av balansomslutning). <br /><br />Varierar nettoomsättningen mer än 30 procent mellan åren ska företaget kommentera detta (se egen rubrik i trädet till vänster). Företaget får ange ytterligare nyckeltal. <br /><br /> K2 (BFNAR 2016:10) kapitel 4 punkt 9 och kapitel 5 punkt 5.`,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("./components/CardEditButtons.vue"),
        InfoButton: () => import("./components/InfoButton.vue"),
    },
    watch: {
        inputText(newVal) {
            this.localInputText = newVal;
        },
        localInputText(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
        showComment(newVal) {
            this.$emit("update:showYearlyOverviewComment", newVal);
        },
    },
    methods: {
        handleToggleComment(newShowComment) {
            this.showComment = newShowComment;
        },
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 5,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async create() {
            this.yearlyOverviewData.forEach(row => {
                this.convertToNumber(row, "annualReportYear");
                this.convertToNumber(row, "annualReportYearMinusOne");
                this.convertToNumber(row, "annualReportYearMinusTwo");
                this.convertToNumber(row, "annualReportYearMinusThree");
            });
            this.updatedYearlyOverviewData = this.yearlyOverviewData;
            this.$emit("update-yearly-overview-data", this.updatedYearlyOverviewData);
            this.$emit("create");
            this.$notify.success({ title: 'Sparat "Flerårsöversikt"' });
            this.toggleEdit();
        },
        convertToNumber(row, field) {
            row[field] = Number(row[field]);
        },
        closeModal() {
            this.$emit("close");
            this.toggleEdit();
        },
    },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
